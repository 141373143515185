import React from 'react';
import { bool, func, string, shape } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Modal, InlineTextButton, IconFlag } from '../../components';
import { supportedLanguages } from '../../util/translations';
import defaultConfig from '../../config/configDefault';
import classNames from 'classnames';

import css from './LanguageModal.module.css';

const DEFAULT_LANGUAGE_CODE = 'es';

// LanguageOption Component
const LanguageOption = ({ language, isSelected, onSelectLanguage }) => {
  const languageClasses = classNames(css.language, {
    [css.selectedLanguage]: isSelected,
  });

  return (
    <InlineTextButton
      className={languageClasses}
      onClick={() => onSelectLanguage(language.code)}
      type="button"
    >
      <div className={css.languageIcon}>
        <IconFlag code={language.code} />
      </div>
      <div className={css.languageContent}>
        <div className={css.languageText}>{language.language}</div>
        <div className={css.languageCountry}>{language.country}</div>
      </div>
    </InlineTextButton>
  );
};

LanguageOption.propTypes = {
  language: shape({
    code: string.isRequired,
    language: string.isRequired,
    country: string.isRequired,
  }).isRequired,
  isSelected: bool.isRequired,
  onSelectLanguage: func.isRequired,
};

// LanguageSelector Component
const LanguageSelector = ({ selectedLanguageId, onSelectLanguage }) => (
  <div className={css.languages}>
    {supportedLanguages.map(language => (
      <LanguageOption
        key={language.code}
        language={language}
        isSelected={language.code === selectedLanguageId}
        onSelectLanguage={onSelectLanguage}
      />
    ))}
  </div>
);

LanguageSelector.propTypes = {
  selectedLanguageId: string.isRequired,
  onSelectLanguage: func.isRequired,
};

// LanguageModal Component
const LanguageModal = ({
  className,
  rootClassName,
  id,
  isOpen,
  onCloseModal,
  onManageDisableScrolling,
}) => {
  const selectedLanguageId = defaultConfig.localization.locale || DEFAULT_LANGUAGE_CODE;

  const handleSelectLanguage = locale => {
    const currentLocale = localStorage.getItem('locale');
    if (currentLocale !== locale) {
      localStorage.setItem('locale', locale);
      window.location.reload();
    }
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="LanguageModal.title" />
      </p>
      <LanguageSelector
        selectedLanguageId={selectedLanguageId}
        onSelectLanguage={handleSelectLanguage}
      />
    </Modal>
  );
};

LanguageModal.defaultProps = {
  className: null,
  rootClassName: null,
};

LanguageModal.propTypes = {
  className: string,
  rootClassName: string,
  id: string.isRequired,
  isOpen: bool.isRequired,
  onCloseModal: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(LanguageModal);
