export const getLocalizationLocale = (defaultLocale = 'en') =>
  (typeof window !== 'undefined' && localStorage.getItem('locale')) || defaultLocale;

export const supportedLanguages = [
  {
    code: 'en',
    language: 'English',
    country: 'United States',
  },
  {
    code: 'es',
    language: 'Spanish',
    country: 'Spain',
  },
  {
    code: 'de',
    language: 'German',
    country: 'Germany',
  },
];
